.login-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
    background-color: #f7f7f7;
}

.login-card {
    width: 100%;
    max-width: 400px;
    padding: 25px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: white;
}

.login-form {
    margin-top: 20px;
}

.site-form-item-icon {
    color: rgba(0, 0, 0, .25);
}

.login-form-button {
    height: 40px;
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
}

.login-form-forgot {
    display: block;
    text-align: right;
    margin-bottom: 10px;
}

@media (min-width: 768px) {
    .login-card {
        margin: auto;
    }
}

@media (min-width: 1024px) {
    .login-card {
        margin: auto;
    }
}
