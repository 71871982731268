
.gray-divider {
    height: 1px;
    background-color: #b4adad;
    margin: 20px 5%;
}

.custom-form-item .ant-form-item-label {
    padding-bottom: 4px;
}

.custom-checkbox-item {
    margin-bottom: 8px;

    .ant-form-item-label {
        display: none;
    }
}
