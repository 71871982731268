html {
  box-sizing: border-box;
  overflow-x: hidden;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

* {
  font-family: 'Roboto Condensed', sans-serif;
}

.container {
  margin: 30px 10% 0px 10%;
}

table td.ant-table-cell { padding: 8px !important; }
table th.ant-table-cell { padding: 8px !important; }